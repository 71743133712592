import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { DatePipe, Location } from "@angular/common";
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { UserService } from 'src/app/services/user.service';
import { BooleanTaskDto, SortOrder, SortingElement, TasksStatus } from 'src/app/web-api-client';
import { CommonService } from 'src/app/services/common/common.service';
import { FilterParameter } from 'src/app/models/FilterPrameter';
import { parseDate } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-boolean-task-list',
  templateUrl: './boolean-task-list.component.html',
  styleUrls: ['./boolean-task-list.component.scss']
})
export class BooleanTaskListComponent implements OnInit {

  // loading indicator
  loadingBooleanTask: boolean = false;
  dateTime = new Date()
  // take the process as input
  @Input() process: string;
  // take the title as input
  @Input() title: string;
  // input for pagination
  @Input() page: number = 1;
  @Input() pageSize: number = 30;
  @Input() displayFilter: boolean = false;
  @Input() onOverview: boolean = false;

  @Input() statusFilter: number = null;
  @Input() tableView: boolean;
  @Input() headers: { head: string, property: string }[] = [];
  // hold the boolean tasks
  items: BooleanTaskDto[] = [];
  @Input() subscriberId: string;
  @Input() isOnClientView: boolean = false;
  @Input() networkMemberId: string = "";
  @Input() sortingElements: SortingElement[] = [];

  isNetworkMember: boolean;
  hideShowMoreBtn: boolean = false;
  isMuView: boolean = false;
  processId: string;
  params: ParamMap;
  lastRefreshDate: string = ""

  // constructor
  constructor(
    private activatedroute: ActivatedRoute,
    private booleanTaskService: BooleanTaskService,
    public location: Location,
    private userService: UserService,
    public commonService: CommonService,
    private datePipe: DatePipe) {
    var isMuView = JSON.parse(localStorage.getItem("isApprovalMultipoolView"));
    if (isMuView == null) {
      isMuView = false;
    }
    this.updateProcess(isMuView);
    this.processId = this.process;
  }

  async ngOnInit() {
    if (this.processId && this.processId != this.process) {
      this.process = this.processId;
    }

    await this.checkUserRoles();
    this.lastRefreshDate = await this.commonService.getHangfireJobStartDateExecution(this.process);
  }

  changeIsMuView(muView: boolean) {
    this.updateProcess(muView);
    this.updateTitle(muView);

    this.processId = this.process;
    this.items = [];

    this.ngOnInit()
  }

  updateProcess(muView: boolean) {
    this.isMuView = muView
    if (muView) {
      this.process = "approval-mu"
    } else {
      this.process = "approval"
    }

    localStorage.setItem("isApprovalMultipoolView", JSON.stringify(muView));
  }

  updateTitle(muView: boolean) {
    if (muView) {
      this.title = "Multipool Approvals"
    } else {
      this.title = "Approvals"
    }
  }

  async checkUserRoles() {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember)

    if (this.isNetworkMember && this.headers) {
      this.headers = this.headers?.filter(x => x.head != "Network Member");
    }

    this.activatedroute.queryParamMap.subscribe(async (params) => await this.loadtasks(params));
  }

  async loadMore() {
    if (!this.loadingBooleanTask) {
      this.page++
      await this.loadtasks(null, true);
    }
  }

  getApprovalDate(booleanTask: BooleanTaskDto) {
    return booleanTask.approvalDate == undefined || booleanTask.approvalDate.getFullYear() == 1 ? "Not approved yet" : this.datePipe.transform(booleanTask.approvalDate);
  }

  getDueDate(booleanTask: BooleanTaskDto) {
    return booleanTask.overdueDate == undefined || booleanTask.overdueDate.getFullYear() == 1 ? "/" : this.datePipe.transform(booleanTask.overdueDate);
  }

  async loadtasks(params?: any, loadMore?: boolean) {
    if (params) {
      this.params = params;
    }
    else {
      params = this.params;
    }

    if (!loadMore) {
      this.items = [];
      this.page = 1;
    }

    // loading started
    this.loadingBooleanTask = true;

    let newItems: BooleanTaskDto[] = [];

    if (this.process) {
      var nmId = (params?.get("networkMemberId") ?? localStorage.getItem('networkMemberId'));
      var clientId = params?.get("clientId");
      var clientName = params?.get("clientName");
      var subscriberId = params?.get('subscriberId');

      var storageStatus = localStorage.getItem('status');
      var status: TasksStatus = storageStatus == "null" ? null : parseInt(storageStatus);

      var storageDueDate = localStorage.getItem('dueDate');
      var DueDate: Date = storageDueDate == null ? null : parseDate(storageDueDate + "-01-01")

      //DueDate =  this.datePipe.transform(DueDate, "dd/MM/yyyy");
      var filterParameter: FilterParameter = {} as FilterParameter;
      filterParameter.NetworkMemberIds = nmId == null ? null : [nmId];
      filterParameter.ClientId = clientId;
      filterParameter.ClientName = clientName;
      filterParameter.SubscriberId = subscriberId;
      filterParameter.Status = status
      filterParameter.ProcessId = this.process;
      filterParameter.OverdueDate = DueDate?.getFullYear();

      //Sort by Status
      var sortBystatus = new SortingElement();
      sortBystatus.columnName = "status"
      sortBystatus.sortOrder = SortOrder.Ascending
      this.sortingElements.push(sortBystatus);

      //Sort by DueDate
      var sortByDueDate = new SortingElement();
      sortByDueDate.columnName = "duedate"
      sortByDueDate.sortOrder = SortOrder.Descending
      this.sortingElements.push(sortByDueDate);

      //Sort by Client name
      var sortByClient = new SortingElement();
      sortByClient.columnName = "client"
      sortByClient.sortOrder = SortOrder.Ascending
      this.sortingElements.push(sortByClient);

      if (this.isNetworkMember) {
        newItems = await this.booleanTaskService.getMyOrganization(filterParameter, this.sortingElements, this.page, this.pageSize);
      } else {
        if (nmId == null) {
          filterParameter.NetworkMemberIds = [];
        }

        newItems = await this.booleanTaskService.getTasks(filterParameter, this.sortingElements, this.page, this.pageSize);
      }

      if (newItems.length < this.pageSize) {
        this.hideShowMoreBtn = true;
      } else {
        this.hideShowMoreBtn = false;
      }

      var concat = this.items.concat(newItems);
      this.items = concat;
    }

    this.sortingElements = []
    this.loadingBooleanTask = false;
  }

  formatdate(dateYear: Date) {
    var year = dateYear.getFullYear();
    if (year === 1) {
      return "/";
    }
    return year;
  }

  getMetaData(task: BooleanTaskDto) {
    if (task.sourceId.startsWith("SA")) {
      const parsed = JSON.parse(task.metaData);
      const approvalQuestions = parsed.ApprovalQuestions;

      if (approvalQuestions.length > 0) {
        const periodEndDate = Date.parse(approvalQuestions[0]?.PeriodEndDate);

        if (!isNaN(periodEndDate)) {
          return this.formatdate(new Date(periodEndDate));
        }
        return "";

      }
      else {
        return "";
      }
    }
  }
}
