import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalQuestion } from 'src/app/models/approvalQuestion';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { BooleanTaskDto, TasksStatus, UserDto } from 'src/app/web-api-client';
import * as XLSX from 'xlsx-js-style';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from 'src/app/services/comment.service';
import { AddCommentDto } from 'src/app/models/AddCommentDto';
import { NotificationFrequency } from 'src/app/models/enums/NotificaionFrequency';
import { NotificationType } from 'src/app/models/enums/NotificationType';
import { CommentType } from 'src/app/models/enums/CommentType';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-approvals-detail-page',
  templateUrl: './approvals-detail-page.component.html',
  styleUrls: ['./approvals-detail-page.component.scss']
})

export class ApprovalsDetailPageComponent implements OnInit {
  @Input() page: number = 1;
  @Input() pageSize: number = 20;
  @Input() onOverview: boolean = false;

  hideShowMoreBtn: boolean = false;

  // hold the task id
  taskId: string;
  task: BooleanTaskDto;
  // hold the list of approval questions
  approvalQuestions: ApprovalQuestion[] = [];
  clientName: string;
  // loading indicator
  loadingTask: boolean = false;
  country: string;
  gfg = false;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  networkMemberId: string;
  networkMemberName: string;
  status: TasksStatus;
  isMuTask: boolean = false;
  clientNameList: string[] = [];
  processId: string;
  periodEnd: string;
  handledByNetworkMemberTag: string = "Handled by Network Member";
  handledByInsuropeTag: string = "Handled by Insurope";
  user: UserDto;
  isNetworkMember: boolean;

  constructor(private activatedroute: ActivatedRoute, public booleanTaskService: BooleanTaskService, private commentService: CommentService, private userService: UserService, private router: Router) {
    this.booleanTaskService.activeBooleanTask = null;
  }

  // oninit method
  async ngOnInit() {
    // loading started
    this.loadingTask = true;

    this.user = await this.userService.getCurrentUser();
    this.isNetworkMember = this.user.roles.includes("NetworkMember");

    // get the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");

    try {
      // get the task by id
      this.task = await this.booleanTaskService.getBooleanTaskById(this.taskId);
      this.commentService.task = this.task;
      this.isMuTask = this.task.processId == "approval-mu";
      this.clientName = this.task?.title;

      let networkMembers = this.task.networkMember;
      this.networkMemberId = networkMembers?.id;
      this.networkMemberName = networkMembers?.accountName;

      this.status = this.task.status.value;
      this.processId = this.task.processId;

      this.commentService.discussion = await this.commentService.getDiscussionByTaskId(this.taskId);
      this.commentService.defaultTags = await this.commentService.getDefaultTags();
      this.commentService.taskSubscribers = await this.commentService.getTaskSubescribersByDiscussionId(this.task.id);
    }
    catch (error) {
      if (error.status === 404) {
        // if the illustration is not found, redirect to the 404 page
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    }

    // loading finished
    this.loadingTask = false;

    // get the metadata of the active task
    this.getMetaData(this.task, 1, 20);
    this.commentService.upsertDefaultTag(this.handledByNetworkMemberTag);
    this.commentService.upsertDefaultTag(this.handledByInsuropeTag);
  }

  getMetaData(task: BooleanTaskDto, page: number, pageSize: number) {
    // parse the metadata
    const parsed = JSON.parse(task.metaData);
    // save the approval questions
    this.approvalQuestions = parsed.ApprovalQuestions.filter((x: ApprovalQuestion) => x.Approved == false);
    this.clientNameList = this.approvalQuestions.map(x => x.ClientName);
    this.periodEnd = this.isMuTask ? "" : this.approvalQuestions[0]?.PeriodEndDate?.toString().split('-')[0];

    if (this.approvalQuestions.length <= this.pageSize) {
      this.hideShowMoreBtn = true;
    }

    this.approvalQuestions.forEach(question => {
      question.ApprovalLetter = question.ApprovalLetter?.replace(/\r\n  \?/g, '<br>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp; ').replace(/\r\n/g, '<br>');
    });

    // sort the approval questions by client name
    this.approvalQuestions = this.approvalQuestions.sort((a, b) => a.ClientName.toLowerCase() <= b.ClientName.toLowerCase() ? -1 : 1);
    // paginate the approval questions
    this.approvalQuestions = this.approvalQuestions.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
  }

  exportexcel(): void {
    //Get table element by id
    let element = document.getElementById('excel-table');

    // Convert the table to a worksheet, filtering rows based on header values
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    // Get the worksheet range
    const filteredData = this.filterOutTheColumnsWithoutHeaders(worksheet);

    // Create a new worksheet from filtered data
    const filteredWorksheet = XLSX.utils.aoa_to_sheet(filteredData);
    // Get the filtered range of the new worksheet
    const filteredRange = XLSX.utils.decode_range(filteredWorksheet['!ref']!);

    // Add formatting to the new worksheet
    this.addFormatting(filteredRange, filteredWorksheet);

    // Generate workbook and add the worksheet
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, filteredWorksheet, 'Sheet1');

    // Save to file
    XLSX.writeFile(workBook, this.task.networkMember.accountName + ".xlsx");
  }

  private filterOutTheColumnsWithoutHeaders(worksheet: XLSX.WorkSheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref']!);

    // Get headers from the first row
    let headers = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: range.s.r };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      const cell = worksheet[cellRef];
      headers.push(cell ? cell.v : null);
    }

    // Filter out columns where headers are empty
    const nonEmptyColumns = headers.map((header, index) => header ? index : null).filter(index => index !== null);

    // Create a new worksheet with filtered data
    const filteredData = [];
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const row = [];
      for (let C of nonEmptyColumns) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        row.push(worksheet[cellRef]?.v || '');
      }
      filteredData.push(row);
    }
    return filteredData;
  }

  private addFormatting(range: XLSX.Range, worksheet: XLSX.WorkSheet) {
    const colWidths: number[] = new Array(range.e.c - range.s.c + 1).fill(0);
    const rowCount = range.e.r
    const columnCount = range.e.c

    for (let row = 0; row <= rowCount; row++) {
      for (let col = 0; col <= columnCount; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellRef];

        //Set the width of the cell based on the content length
        const cellContentLength = cell?.v ? cell.v.toString().length : 0;
        let maxColumnWidth = Math.max(colWidths[col], cellContentLength);
        colWidths[col] = Math.min(maxColumnWidth, 40) + 0.1;

        // Add this format to every cell
        cell.s = {
          alignment: {
            vertical: "top",
            wrapText: true,
          },
        };

        // Format headers bold
        if (row === 0) {
          cell.s = {
            //spreads in previous cell settings
            ...cell.s,
            font: { bold: true },
          }
        }
      }
    }

    // Set the width of each column based on the calculated maximum content length
    worksheet['!cols'] = colWidths.map(width => ({ wch: width + 2 }));
  }

  loadMore() {
    // get the metadata of the active task
    this.getMetaData(this.task, this.page, this.pageSize += 20);
  }

  Reply(clientName: string, ApprovalLetter: string) {
    var tagReplyParameter = { tagName: clientName, message: ApprovalLetter };
    this.commentService.setClientReplyTagValue.next(tagReplyParameter);
  }

  async ApproveDisapproveClient(approvalQuestion: ApprovalQuestion) {
    // Set the approval date and approved by network member to true and update the task
    var approvedByNetworkMember = approvalQuestion.ApprovedByNetworkMember;

    if (!approvedByNetworkMember) {
      approvedByNetworkMember = true;
      approvalQuestion.networkMemberApprovalDate = new Date(new Date().getUTCDate());

      var commentMessage = "Approved by Network Member: " + approvalQuestion.ClientName + " " + approvalQuestion.SubsidiaryLabel;
      if (approvalQuestion.ApprovalLetter && approvalQuestion.ApprovalLetter.trim().length > 0) {
        commentMessage += "<p><br> Approval Comment:<br>" + approvalQuestion.ApprovalLetter + "</p>";
      }

      // Add a new comment
      var tags: string[] = [approvalQuestion.ClientName, this.handledByNetworkMemberTag];

      var CommentParameter: AddCommentDto = {
        taskId: this.taskId,
        content: commentMessage,
        tags: tags,
        customSubscriberProvider: "",
        processName: this.processId,
        clientName: this.clientName,
        networkMemberName: this.networkMemberName,
        files: null,
        country: this.country,
        periodEnd: this.periodEnd,
        commentType: CommentType.Reply,
        isDeleted: false,
        isEdited: false,
        isHtmlText: false,
        notificationFrequency: NotificationFrequency.Scheduled,
        notificationType: NotificationType.EmailNotification
      }

      await this.commentService.addComment(CommentParameter).then();
    }
    else {
      approvedByNetworkMember = false;
    }

    approvalQuestion.ApprovedByNetworkMember = approvedByNetworkMember;
    this.task.metaData = JSON.stringify({ ApprovalQuestions: this.approvalQuestions });
    await this.booleanTaskService.updateTask(this.task);
  }

  loadAll() {
    // get the metadata of the active task
    this.getMetaData(this.task, 1, 300);
    this.hideShowMoreBtn = true;
  }

  GetButtonName(isApproveByNetworkMember: boolean) {
    if (isApproveByNetworkMember) {
      if (this.isNetworkMember) {
        return "Approved";
      }
      else {
        return "Disapprove";
      }
    }

    return "Approve";
  }
}
