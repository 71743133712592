import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IllustrationService } from 'src/app/services/illustration/illustration.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DiscussionDto, IllustrationRequestDto, TaskSubscribersDto, TaskUserSusbscriptionDto } from 'src/app/web-api-client';
import { revisionOptionMpp } from 'src/app/models/RevisionOptionMpp';
import * as FileSaver from 'file-saver';
import { DocumentService } from 'src/app/services/document.service';
import { ToastrService } from 'ngx-toastr';
import { CommentService } from 'src/app/services/comment.service';
import { ProcessIds } from 'src/app/models/enums/processIds';

@Component({
  selector: 'app-illustration-detail',
  templateUrl: './illustration-detail.component.html',
  styleUrls: ['./illustration-detail.component.scss']
})
export class IllustrationDetailComponent implements OnInit {

  // hold the illustration id
  illustrationId: string;
  // hold the illustration
  illustration: IllustrationRequestDto;
  // hold the uploaded files
  uploadedFiles: File[] = [];
  loadingMppFile: boolean = false;
  revisionOptionMpp: revisionOptionMpp[] = [];
  illustrationAttachments: string[] = [];
  discussion: DiscussionDto;
  processId: string = ProcessIds.illustrations;

  taskSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  // constructor
  constructor(
    private activatedroute: ActivatedRoute,
    public location: Location,
    public illustrationService: IllustrationService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private router: Router,
    public documentService: DocumentService,
    private toastrService: ToastrService,
    private commentService: CommentService) { }

  // oninit method
  ngOnInit(): void {
    // get the illustration
    this.getIllustration();
  }

  // method to get the illustration
  async getIllustration() {
    // take the parameters from the url
    this.illustrationId = this.activatedroute.snapshot.paramMap.get("id");

    try {
      // get the illustration by id
      this.illustration = await this.illustrationService.getIllustrationById(this.illustrationId);
      this.commentService.task = this.illustration;
      this.discussion = await this.commentService.getDiscussionByTaskId(this.illustration.id);
      this.commentService.discussion = this.discussion;
      this.commentService.comments = this.commentService.discussion?.comments;

      this.illustration.illustrationRevisions.sort((a, b) => a.revisionNumber >= b.revisionNumber ? -1 : 1).forEach(x => {
        let filePathList: string[] = [];

        //Get MPPs filePath
        let mppFilePaths = x.illustrationRevisionOptions.filter(t => t.sentToNetworkMemberDate > new Date('1/1/0001 12:00:00 AM') && t.pdfmppPath != null && t.deleted != true)
          .map(function (a) { return "GeneratedMPP/" + a.id + "/" + a.pdfmppName });
        filePathList.push(...mppFilePaths);

        //Get MLOCs filePath
        let locFilePaths = x.illustrationRevisionOptions.filter(t => t.sentToNetworkMemberDate > new Date('1/1/0001 12:00:00 AM') && t.pdflocPath != null && t.deleted != true)
          .map(function (a) { return "GeneratedMPP/" + a.id + "/" + a.pdflocName });
        filePathList.push(...locFilePaths);

        this.revisionOptionMpp.push({ revisionId: x.id, filesPath: filePathList, revisionNumber: x.revisionNumber, finalizedDate: x.finalizedSupportDate })
      }
      );


      this.illustration.illustrationRevisions.forEach((rev) => {
        rev.illustrationRevisionAttachments.map(att => this.illustrationAttachments.push(att.filePath + "/" + att.fileName));
      });

      let internalSubscriber: TaskUserSusbscriptionDto = {
        active: true,
        email: this.illustration.salesRepresentative.mail,
        preferredEmail: this.illustration.salesRepresentative.mail
      } as TaskUserSusbscriptionDto;

      let networkMemberSubscribers: TaskUserSusbscriptionDto[];

      this.illustration.networkMemberSubscribers.forEach(subscriberEmail => {
        let networkMemberSubscriber: TaskUserSusbscriptionDto = {
          active: true,
          email: subscriberEmail,
          preferredEmail: subscriberEmail
        } as TaskUserSusbscriptionDto;

        this.taskSubscribers.networkMemberSubscribers.push(networkMemberSubscriber);
      });

      this.taskSubscribers.insuropeSubscribers.push(internalSubscriber);

      if (this.illustration.salesRepresentative.mail != this.illustration.supportRepresentative.mail) {
        let internalSubscriber: TaskUserSusbscriptionDto = {
          active: true,
          email: this.illustration.supportRepresentative.mail,
          preferredEmail: this.illustration.supportRepresentative.mail
        } as TaskUserSusbscriptionDto;

        this.taskSubscribers.insuropeSubscribers.push(internalSubscriber);
      }
    }
    catch (error) {
      if (error.status === 404) {
        // if the illustration is not found, redirect to the 404 page
        this.router.navigate(['/404'], { skipLocationChange: true });
      }
    }

    // set the title
    this.titleService.setTitle(this.illustration.clientName + " | Insurope");
  }

  // sanitize the url to download the files
  sanitize(url: string) {
    // return safe url
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async downloadMppFile(path: string) {
    this.loadingMppFile = true;
    try {
      var file = await this.documentService.geIllustrationtDocument(path);
    } catch (e) {
      this.loadingMppFile = false;
      this.toastrService.error("An error occured during the MPP file downloading")
    }
    const imageBlob = this.dataURItoBlob(file);
    var newPath = this.documentService.sanitizeFileName(path, ".pdf");
    var test = new File([imageBlob], newPath, { type: 'application/pdf' });
    FileSaver.saveAs(test)
    this.loadingMppFile = false;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }
}
