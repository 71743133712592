import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment, CommentDto, CommentTagDto, CommentType } from 'src/app/web-api-client';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { DocumentService } from 'src/app/services/document.service';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { TagReplyParameter } from 'src/app/models/TagReplyParameter';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})

export class CommentItemComponent implements OnInit {
  @Input() comment?: CommentDto
  @Input() discussionId?: string
  @Input() isTagSystemApplicable: boolean = false;
  @Input() userId: string;
  @Input() clientNameList: string[];

  @Output() filterByTagEvent = new EventEmitter<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  attachments: Attachment[] = [];
  pseudo: string = "";
  faFilePdf = faFilePdf;
  faFileExcel = faFileExcel;
  faFileCsv = faFileCsv;
  faFile = faFile;
  filesPath: string[] = [];
  commentClass: string = "comment-item"
  commentTags: CommentTagDto[] = []
  faCircleXmark = faCircleXmark;
  model: string;
  allDiscussionTags: string[];
  defaultTags: string[];
  allTags: string[];
  taskId: string = this.activatedroute.snapshot.paramMap.get("id");
  maxLength: number = 300;
  onKeyDown: boolean = true;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  showMore: boolean = false;
  lessCommmentText: string = "";
  moreCommmentText: string = "";
  previewTextLength: number = 500;
  canBeEdited: boolean = false;
  canBeDeleted: boolean = false;
  plainTextComment: string = "";

  isNetworkMember: boolean;
  isCollaborator: boolean;

  constructor(private sanitizer: DomSanitizer, private commentService: CommentService, public documentService: DocumentService, private activatedroute: ActivatedRoute, private userService: UserService) { }

  async ngOnInit() {
    this.attachments = this.comment?.attachments;
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);
    var isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);

    if (isNetworkMember) {
      //no remove characters
      this.onKeyDown = false;
      //no write
      this.maxLength = 0
    }
    else {
      this.maxLength = 300;
      this.onKeyDown = true;
    }

    this.canBeEdited = this.comment.commentType != CommentType.Automated && this.comment.userId == this.userId;
    this.canBeDeleted = this.comment.userId == this.userId || this.isCollaborator;
    this.commentTags = this.commentService.comments.filter(x => x.id == this.comment.id).map(x => x.tags).flat();

    let discussionTags = this.commentService.discussion?.comments.map(x => x.tags).flat();
    this.allDiscussionTags = [...new Set(discussionTags?.map(({ tagName }) => tagName))];
    this.defaultTags = this.commentService.defaultTags?.map(({ tagName }) => tagName);
    this.allTags = [...new Set(this.allDiscussionTags?.concat(this.defaultTags).concat(this.clientNameList))];

    this.generateCommentPreview();

    this.getPseudo();
    this.changeClass();

    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
  }

  search: OperatorFunction<string, string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === '' ? this.allTags : this.allTags.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10),
      ),
    );
  };

  getPseudo() {
    this.pseudo = this.comment.firstName[0] + this.comment.lastName[0];
  }

  convertFileType(type: string) {
    switch (type) {
      case ".pdf":
        return this.faFilePdf;
      case ".xsl":
        return this.faFileExcel;
      case ".csv":
        return this.faFileCsv;
      default:
        return this.faFile;
    }
  }

  sanitize(url: string) {
    // return safe url
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  addReplyTags() {
    var replyTags = this.commentTags.map(x => x.tagName);
    replyTags = replyTags.filter(x => x != "Handled by Network Member");
    replyTags.push("Handled by Insurope");

    var TagReplyParameter: TagReplyParameter[] = replyTags.map(x => { return { tagName: x, message: "" } });
    this.commentService.setCommentReplyTagsList.next(TagReplyParameter);
  }

  changeClass() {
    if (this.userId == this.comment?.userId) {
      this.commentClass = "my-comment comment-item";
    }
  }

  async deleteTag(id: string) {
    var index = this.commentTags.findIndex(x => x.id == id);
    this.commentTags.splice(index, 1);
    await this.commentService.deleteCommentTags(id, this.discussionId, this.comment.id).then()
  }

  async addTag($event: { preventDefault: () => void; }, tag: string) {
    if (this.commentTags.find(x => x.tagName == tag) == null && tag != null) {
      var commentId = this.comment.id
      var newTag = await this.commentService.addCommentTag([tag], this.discussionId, commentId);
      this.commentTags = this.commentTags.concat(newTag);
      $event.preventDefault();
    }

    this.model = ""
  }

  // method to download the file
  async downloadCommentFile(file: Attachment) {
    // download the file from the server
    let download = "data:application/octet-stream;base64," + await this.commentService.downloadFile(this.comment?.id, this.discussionId, file.id);
    // download the file
    var a = document.createElement('a');
    a.setAttribute('href', download);
    a.setAttribute('download', file.name);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  filterByTag(tag: string) {
    // filter the comments by tag
    this.filterByTagEvent.emit(tag);
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    this.updateLessCommentText();
  }

  generateCommentPreview() {
    if (this.comment?.content != null && this.comment?.content?.length > 0) {
      this.plainTextComment = this.convertToPlainText(this.comment.content);
      this.moreCommmentText = this.plainTextComment.slice(this.previewTextLength);
      this.lessCommmentText = this.plainTextComment.slice(0, this.previewTextLength);

      this.updateLessCommentText();
    }
  }

  updateLessCommentText() {
    if (!this.showMore && this.plainTextComment.length > this.previewTextLength) {
      this.lessCommmentText = this.lessCommmentText + "...";
    }
    else {
      this.lessCommmentText = this.plainTextComment.slice(0, this.previewTextLength);
    }
  }

  expandCollapseComment(shouldExpand: boolean) {
    this.showMore = shouldExpand;
    this.updateLessCommentText();
  }

  // delete a comment
  async deleteComment() {
    await this.commentService.deleteComment(this.taskId, this.comment.id);
  }

  async editeComment() {
    this.commentService.editCommentEvent.next(this.comment);
  }

  convertToPlainText(text: string): string {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the input HTML string into a document
    const doc = parser.parseFromString(text, 'text/html');

    // Check if there's a paragraph element that is not empty
    const paragraphElements = doc.querySelectorAll('p');
    const titleElement = doc.querySelector('b');
    const listElement = doc.querySelector('ul');

    // Initialize HTML with an empty string
    let html = '';

    // Process paragraph elements if present
    paragraphElements.forEach((p) => {
      const paragraphText = p.textContent?.trim();
      if (paragraphText) {
        html += `<p>${paragraphText}</p>\n`; // Add paragraph to HTML
      }
    });

    if (html.trim().length == 0) {
      html = `<p></p>\n`;
    }

    // If there's a title, add it
    if (titleElement) {
      html = html.replace(/<p>.*$/gm, `<p><b>${titleElement.textContent?.trim()}</b><br>\n`);
    }

    // Process the list if present
    if (listElement) {
      html += `<ul>\n`;
      const items = listElement.querySelectorAll('li');

      // Iterate through the list items
      items.forEach((item) => {
        const lines = item.textContent?.trim().split('\n').map(line => line.trim()).filter(Boolean) || [];
        let mainItem = true; // To track main vs nested items

        // Iterate through the extracted lines
        lines.forEach(line => {
          if (line.startsWith('-')) {
            if (!mainItem) {
              html += `</ul>\n`; // Close the nested list if it was open
              mainItem = true; // Reset for the next main item
            }
            html += `<li>${line.substring(1).trim()}</li>\n`; // Add main list item
          } else if (line.startsWith('?')) {
            if (mainItem) {
              html += `<ul>\n`; // Open a nested list
              mainItem = false; // Switch to nested item mode
            }
            html += `<li>${line.substring(1).trim()}</li>\n`; // Add nested list item
          } else {
            // If the line doesn't start with a dash or question mark,
            // treat it as a main item if it’s the first non-empty line.
            if (mainItem) {
              html += `<li>${line}</li>\n`; // Add it as a main item
            }
          }
        });

        if (!mainItem) {
          html += `</ul>\n`; // Close the nested list if it was open
        }
      });
      html += `</ul>\n`;
    }

    return html;
  }
}
