import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { DiscussionDto, TaskSubscribersDto, Comment, TasksStatus, CommentDto } from 'src/app/web-api-client';
import { faTrashCan, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import jsPDF from 'jspdf';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TagReplyParameter } from 'src/app/models/TagReplyParameter';
import { CommentFormDto } from 'src/app/models/CommentFormDto';
import { AddCommentDto } from 'src/app/models/AddCommentDto';
import { ProcessIds } from 'src/app/models/enums/processIds';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { EditCommentDto } from 'src/app/models/EditCommentDto';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})

export class CommentListComponent implements OnInit {
  @Input() taskId: string = this.activatedroute.snapshot.paramMap.get("id");
  @Input() status: TasksStatus;
  @Input() customSubscriberProvider: string = "";
  @Input() networkMemberId: string;
  @Input() networkMemberName: string;
  @Input() clientName: string;
  @Input() clientNameList: string[];
  @Input() country: string;
  @Input() periodEnd: string;
  @Input() customProcessId: string = "";
  @Input() illustrationRequestSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @Input() service: any;
  @Input() isTagSystemApplicable: boolean = true;

  @Output() commentIdReply = new EventEmitter<string[]>();

  replyTagsSubject: Subject<TagReplyParameter[]> = new Subject<TagReplyParameter[]>();
  tagSelected: Subject<string> = new Subject<string>();
  quillEditorSetFocus: Subject<void> = new Subject();

  lastExportedDate: Date;
  userId: string;
  dateTime = new Date()
  discussion: DiscussionDto;
  comments: Comment[] = [];
  commentId: number = 0;
  faTrashCan = faTrashCan;
  faCircleXmark = faCircleXmark;
  isNetworkMember: boolean;
  isClient: boolean;
  IsCollaborator: boolean;
  searchResult: [];
  replyTags: string[] = [];
  selectedTag: string = "";
  processId: string;
  filteredComments: Comment[] = [];
  isAllToggleShowMoreComplete: boolean = false;
  loading: boolean = false;

  replyTagsSubscription: Subscription;
  commentSubscribersSubscription: Subscription;

  taskSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @ViewChild('discussionTable') discussionTable!: ElementRef;
  @ViewChild('searchTagField', { static: true }) searchTagField: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(private commentService: CommentService, private userService: UserService, private activatedroute: ActivatedRoute) { }

  ngOnInit() {
    this.processId = this.commentService.task.processId;
    if (this.customProcessId != "" && (this.processId == "" || this.processId == null)) {
      this.processId = this.customProcessId;
    }

    this.comments = this.commentService.comments;
    this.filteredComments = this.comments;
    this.discussion = this.commentService.discussion;

    this.commentSubscribersSubscription = this.commentService.updateCommentSubscribers.subscribe((subscribers) => {
      this.taskSubscribers = subscribers;
    });

    this.getSubscibers()
  }

  // Load subscribers from the service
  async getSubscibers() {
    try {
      if (this.customSubscriberProvider.trim() !== "") {
        this.taskSubscribers = await this.commentService.getIllustrationTaskSubscriber(this.taskId,
          this.illustrationRequestSubscribers.clientSubscribers,
          this.illustrationRequestSubscribers.insuropeSubscribers,
          this.illustrationRequestSubscribers.networkMemberSubscribers
        );
      }
      else {
        this.taskSubscribers = this.commentService.taskSubscribers;
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  // Add a comment at the top of the comments array
  async addComment(response: CommentFormDto) {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isClient = await this.userService.isUserInRole(UserRoles.Client);
    this.IsCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);
    var task = this.commentService.task;

    if (response.isEdit) {
      let editCommentDto: EditCommentDto = {
        TaskId: this.taskId,
        CommentId: response.commentId,
        Content: response.content,
        SendNotification: response.sentNotification,
        PeriodEnd: this.periodEnd,
        ProcessName: this.processId,
        ClientName: this.clientName,
        NetworkMemberName: task.networkMemberName,
        CustomSubscriberProvider: task.customSubscriberProvider,
        Country: this.country,
        Title: task.title,
      };

      await this.commentService.editComment(editCommentDto);
    } else {
      let addCommentDto: AddCommentDto = {
        taskId: this.taskId,
        content: response.content,
        tags: response.commentTags,
        customSubscriberProvider: this.customSubscriberProvider,
        processName: this.processId,
        clientName: this.clientName,
        networkMemberName: this.networkMemberName,
        files: response.attachments,
        country: this.country,
        periodEnd: this.periodEnd,
        commentType: response.commentType,
        isDeleted: false,
        isEdited: false,
        isHtmlText: true,
        notificationFrequency: response.notificationFrequency,
        notificationType: response.notificationType
      };

      var comment = await this.commentService.addComment(addCommentDto).then();

      if (comment != undefined && !(this.processId == ProcessIds.accountInputs || this.processId == ProcessIds.annualReport || this.processId == ProcessIds.illustrations)) {
        if ((this.isNetworkMember || this.isClient) && this.status == TasksStatus.Open) {
          await this.service.changeTaskStatus(this.taskId, TasksStatus.InProgress);
        }

        if (this.IsCollaborator && this.status == TasksStatus.InProgress) {
          await this.service.changeTaskStatus(this.taskId, TasksStatus.Open);
        }
      }
    }
  }

  // export the discussion
  async exportDiscussion() {
    this.loading = true;
    var exportCommentBuilder = "";

    for (let i = 0; i < this.comments.length; i++) {
      if (this.comments[i].content?.trim().length < 1) return "";
      var message = "<blue>" + this.comments[i].firstName + " " + this.comments[i].lastName + " (" + this.comments[i].messageSent.toDateString() + "):</blue>" + "\n";
      message += this.htmlToPlainText(this.comments[i].content);
      message = message.replace("\n\n", "\n");
      exportCommentBuilder += message + "\n\n";
    }

    this.generatePDF(exportCommentBuilder, this.commentService.task.title + " - " + this.commentService.task.networkMember?.accountName + ".pdf");

    this.loading = false;
  }

  generatePDF(text: string, fileName: string = 'Document.pdf') {
    const doc = new jsPDF();
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width;
    const pageHeight = pageSize.height;
    const margin = 10; // Adjust the margin as needed
    const lineSpacing = 7; // Adjust the line spacing as needed

    doc.setFontSize(12);

    // Split the text into pages based on page width and margin
    const lines = text.split('\n');
    let currentPage = 1;
    let y = margin;
    let x = margin;

    for (const line of lines) {
      if (line.startsWith("<blue>") || line.endsWith("</blue>")) {
        doc.setTextColor("#378ac0");
      } else {
        doc.setTextColor(0, 0, 0);
      }
      const lineWidth = doc.getTextWidth(line);

      if (lineWidth > pageWidth - 2 * margin) {
        // Line is too long, split it into multiple lines
        const words = line.split(' ');
        let currentLine = '';

        for (const word of words) {
          const newLine = currentLine + word + ' ';
          const newLineWidth = doc.getTextWidth(newLine);

          if (newLineWidth > pageWidth - 2 * margin) {
            // Line is too long, write the current line and start a new one
            doc.text(currentLine, x, y);
            y += lineSpacing; // Increase y by lineSpacing
            currentLine = word + ' ';
          } else {
            currentLine = newLine;
          }
        }

        currentLine = currentLine.replace("<blue>", "");
        currentLine = currentLine.replace("</blue>", "");

        // Write the last line
        doc.text(currentLine, x, y);
        y += lineSpacing; // Increase y by lineSpacing
      } else {
        // Line fits within the page, write it as is
        var tempLine = line.replace("<blue>", "");
        tempLine = tempLine.replace("</blue>", "");

        doc.text(tempLine, x, y);
        y += lineSpacing; // Increase y by lineSpacing
      }

      if (y > pageHeight - margin) {
        // Page is full, start a new page
        currentPage++;
        doc.addPage();
        y = margin;
        x = margin;
      }
    }

    doc.save(fileName);
  }

  copySubscriberEmail(val: string) {
    navigator.clipboard.writeText(val);
  }

  getNetworkMemberId() {
    return this.networkMemberId;
  }

  async addSubscriber(event: { id: string; }) {
    var sub = await this.commentService.addSubscriber(this.taskId, event.id, this.processId);
    this.taskSubscribers = sub;
  }

  async deleteSubscriber(taskSubscribers: TaskSubscribersDto) {
    var subs = await this.commentService.deleteSubscriber(this.taskId, taskSubscribers.id);
    this.taskSubscribers = subs;
  }

  isCommentDeletable(comment: Comment) {
    var limitDate = comment.messageSent.getDate() + 1;
    var deleteComment = true;

    if (comment.userId == this.userId) {
      deleteComment = false;
      if (comment.attachments?.length > 0 && limitDate < this.dateTime.getDate()) {
        deleteComment = true;
      }
    }
    return deleteComment;
  }

  async loadTagByName(tagName: string) {
    if (tagName == '') {
      this.filteredComments = this.comments;
    } else {
      this.filteredComments = this.comments.filter(comment => comment.tags.some((e) => e.tagName.toLowerCase().startsWith(tagName.toLowerCase())))
      this.searchResult = [];
      this.selectedTag = tagName;
    }
  }

  async clearSearch() {
    this.searchResult = [];
    this.filteredComments = this.comments;
    this.searchTagField.nativeElement.value = "";
  }

  updateCommentList(comments: CommentDto[]) {
    this.filteredComments = comments;
  }

  updateSelectedTag(tag: string) {
    this.tagSelected.next(tag);
  }

  setQuillFocusEvent() {
    this.quillEditorSetFocus.next();
  }

  htmlToPlainText(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;

    return this.traverseDOM(div);
  }

  traverseDOM(node: Node): string {
    let text = '';

    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE && node instanceof Element) {
      if (node.tagName === 'BR') {
        text += '\n';
      } else if (node.tagName === 'LI') {
        text += '\n- ' + node.textContent.trim();
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          text += this.traverseDOM(node.childNodes[i]);
        }
      }
    }

    return text;
  }

  onNgDestroy() {
    this.replyTagsSubscription.unsubscribe();
    this.commentSubscribersSubscription.unsubscribe();
  }
}
