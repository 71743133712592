<h1 *ngIf="networkMemberId && isCollaborator">Dashboard of {{networkMemberName}}</h1>
<app-multiple-filter-search [isOnOverwiew]="true"></app-multiple-filter-search>

<div>
  <span *ngIf="isNetworkMember && !title">
    <h1>Your dashboard</h1>
  </span>
  <span *ngIf="isNetworkMember && title">
    <h1>{{ title }}</h1>
  </span>

  <section class="overview-box">
    <div class="card-container mb-3">
      <!-- Show the pooling permissions -->
      <div>
        <app-file-upload-task-list [title]="'Pooling permissions'" process="pooling-permission" [onOverview]="true" [backBtn]="false" [pageSize]="5" [statusFilter]="0" [networkMemberId]="clientId" [isOnClientView]="isOnClientView"
          class="d-flex flex-column h-100" [displayFilter]="false">
        </app-file-upload-task-list>
      </div>
    </div>
  </section>

  <section class="overview-box">
    <div class="card-container mb-3">
      <!-- Show the illustration inputs -->
      <div>
        <app-file-upload-task-list [title]="'Illustration inputs'" [onOverview]="true" process="illustration-input" [backBtn]="false" [pageSize]="5" [statusFilter]="0" [networkMemberId]="clientId" [isOnClientView]="isOnClientView"
          class="d-flex flex-column h-100" [displayFilter]="false">
        </app-file-upload-task-list>

      </div>
    </div>
  </section>

  <!-- <section class="overview-box">
    <div class="card-container mb-3">-->
  <!-- Show the All illustrations -->
  <!--<div>
      <app-illustration-list [pageSize]="5" [displayFilter]="!isCollaborator" [onOverview]="true" class="illustration-overview"></app-illustration-list>
    </div>
  </div>
</section> -->

  <section class="overview-box line-of-2">
    <div class="card-container mb-3">
      <!-- Show the account inputs -->
      <div>
        <app-file-upload-task-list [title]="'Account inputs'" process="account-input" [onOverview]="true" [pageSize]="2" [backBtn]="false" [statusFilter]="0" [networkMemberId]="clientId" [isOnClientView]="isOnClientView"
          class="d-flex flex-column h-100" [displayFilter]="false">
        </app-file-upload-task-list>
      </div>
    </div>

    <div class="card-container mb-3">
      <!-- Show the approval permissions -->
      <div>


        <app-boolean-task-list [pageSize]="2" [title]="'Approvals'" process="approval" [statusFilter]="0" [onOverview]="true" [networkMemberId]="clientId" [isOnClientView]="isOnClientView" [displayFilter]="false">
        </app-boolean-task-list>
      </div>
    </div>
  </section>

  <section class="overview-box">
    <div class="card-container mb-3">
      <!-- Show the payment permissions -->
      <div>
        <app-informative-list [title]="'Loss Settlement Payments'" process="payments" [pageSize]="5" [onOverview]="true" class="d-flex flex-column h-100" [statusFilter]="0" [networkMemberId]="clientId" [isOnClientView]="isOnClientView"
          [displayFilter]="false">
        </app-informative-list>
      </div>

    </div>
  </section>

  <section class="overview-box" *ngIf="isFinancial">
    <div class="card-container mb-3">
      <!-- Show the services fees -->
      <div>
        <app-informative-list [title]="'Services fees'" [onOverview]="true" [pageSize]="5" process="service-fees" [statusFilter]="0" [isOnClientView]="isOnClientView" [networkMemberId]="clientId" [isOnClientView]="isOnClientView"
          class="d-flex flex-column h-100" [displayFilter]="false">
        </app-informative-list>
      </div>

    </div>
  </section>
</div>